import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

import classes from "./LandingPage.module.css";
import lamonaiImg from "../../asset/img/lamonai.svg";
import comraavImg from "../../asset/img/comraav.svg";
import tontinumImg from "../../asset/img/tontinum.svg";
import lavevImg from "../../asset/img/lavev.svg";
import rensfiImg from "../../asset/img/rensfi.png";
import efacUSSDImg from "../../asset/img/efacUSSD.png";
import atikaImg from "../../asset/img/atika.svg"; /*
import traore from '../../asset/img/traore.PNG';
import frederic from '../../asset/img/frederic.jpg';
import syndia from '../../asset/img/syndia.PNG';
import rachida from '../../asset/img/rachida.jpg';
import inoussa from '../../asset/img/inoussa.jpg';
import lamoussa from '../../asset/img/lamoussa.jpeg';
import simon from '../../asset/img/simon.jpeg';*/
import phone from "../../asset/img/phone.svg";
import email from "../../asset/img/email.svg";
import location from "../../asset/img/location.svg";
import Footer from "../../Components/Footer/Footer";

const LandingPage = () => {
  const [requestStatus, setRequestStatus] = React.useState("");
  const [showSpinner, setShowSpinner] = React.useState(false);

  const formInitialValues = {
    nom: "",
    email: "",
    structure: "",
    message: "",
  };

  const formValidation = (values) => {
    const errors = {};

    if (!values.nom) {
      errors.nom = "Doit être indiqué";
    }

    if (!values.email) {
      errors.email = "Doit être indiqué";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Adresse email invalide";
    }

    if (!values.structure) {
      errors.structure = "Doit être indiqué";
    }

    if (values.message.length < 10) {
      errors.message = "10 caractères minimum";
    }

    return errors;
  };

  const formSubmission = (values, { setSubmitting, resetForm }) => {
    setShowSpinner(true);
    const data = {
      nom: values.nom,
      email: values.email,
      structure: values.structure,
      message: values.message,
    };

    axios
      .post("/message/nouveau", data)
      .then((response) => {
        setShowSpinner(false);
        setRequestStatus("ok");
      })
      .catch((error) => {
        setShowSpinner(false);
        setRequestStatus("error");
      });

    resetForm(formInitialValues);
    setSubmitting(false);
  };

  let statusText;

  if (requestStatus === "ok") {
    statusText = <div style={{ color: "#8c72cc" }}>Effectué!</div>;
  } else {
    if (requestStatus === "error") {
      statusText = <div style={{ color: "#FF0077" }}>Erreur survenue</div>;
    } else {
      statusText = null;
    }
  }

  const spinnerDiv = <Spinner animation="border" variant="secondary" />;

  return (
    <div className={classes.FlexColumn}>
      {/* Contact */}
      <div
        className={classes.FlexRow}
        style={{ flexWrap: "wrap", margin: "15px 25px" }}
      >
        <div className={classes.Flex} style={{ margin: "auto 15px" }}>
          <img src={phone} style={{ width: "15px" }} alt="" />
          <span style={{ fontSize: ".8rem", marginLeft: "8px" }}>
            (+226) 25 39 37 81
          </span>
        </div>
        <div className={classes.Flex} style={{ margin: "auto 15px" }}>
          <img src={email} style={{ width: "15px" }} alt="" />
          <span style={{ fontSize: ".8rem", marginLeft: "8px" }}>
            info@cladjen.tech
          </span>
        </div>
        <div className={classes.Flex} style={{ margin: "auto 15px" }}>
          <img src={location} style={{ width: "10px" }} alt="" />
          <span style={{ fontSize: ".8rem", marginLeft: "8px" }}>
            Wem-Tenga, Ouagadougou, Burkina Faso
          </span>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          margin: "0",
          height: "3px",
          backgroundColor: "#8c72cc",
        }}
      />
      <div className={classes.Logo} />
      {/* Header */}
      <div className={classes.Header}>
        <div className={classes.HeadTitle}>Editeur de solutions Blockchain</div>
        <div className={classes.HeadSubtitle}>
          Nous aidons les Entreprises et Startups à profiter du plein potentiel
          de la Technologie des Registres Distribués (Blockchain)
        </div>
      </div>
      {/* Qui sommes-nous */}
      <div className={classes.FlexColumn}>
        <div className={classes.Title}>Qui sommes-nous ?</div>
        <div
          style={{
            backgroundColor: "#8c72cc",
            height: "5px",
            width: "75px",
            marginTop: "-35px",
          }}
        />
        <div className={classes.Apropos}>
          <div className={classes.Left}>
            CLADJEN est une entreprise informatique spécialisée dans le
            développement de solutions Blockchain. Nous offrons à nos clients
            (Entreprises et Startups) de l'expertise numérique sur la
            Technologie des Registres Distribués (Blockchain).
          </div>
          <div className={classes.Right} />
        </div>
      </div>
      {/* Nos services */}
      <div className={classes.FlexColumn}>
        <div className={classes.Title}>Nos services</div>
        <div
          style={{
            backgroundColor: "#8c72cc",
            height: "5px",
            width: "75px",
            marginTop: "-35px",
          }}
        />
        <div className={classes.Services}>
          <div className={classes.Left}>
            <Carousel className={classes.FlexColumn}>
              <Carousel.Item>
                <div className={classes.CarouselItem}>
                  <div
                    style={{
                      color: "#8c72cc",
                      fontSize: "1.5rem",
                      fontWeight: "800",
                      textAlign: "center",
                    }}
                  >
                    Développement d'Applications Web et Mobile
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    Nous vous accompagnons depuis l'analyse des besoins jusqu'au
                    développement effectif de vos applications en suivant la
                    méthode agile. Nous vous assistons GRATUITEMENT pendant
                    trois (03) mois après le déploiement ou la mise en ligne.
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={classes.CarouselItem}>
                  <div
                    style={{
                      color: "#8c72cc",
                      fontSize: "1.5rem",
                      fontWeight: "800",
                      textAlign: "center",
                    }}
                  >
                    Preuve de Concept (PoC)
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    Vous souhaitez réaliser une expérimentation sur la
                    Blockchain ou étudier la faisabilité ou l'intérêt d'une
                    solution intégrant la Technologie des Registres Distribués
                    (Blockchain) ? Vous êtes à la bonne adresse.
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className={classes.Right}>
            <div className={classes.RightContainer} />
          </div>
        </div>
        <div
          className={classes.Services}
          style={{ backgroundColor: "#F2F2F2" }}
        >
          <div className={classes.Left}>
            <div
              className={classes.FlexColumn}
              style={{
                padding: "10px",
                color: "#8c72cc",
                height: "300px",
                fontSize: "1.1rem",
              }}
            >
              <div
                style={{
                  color: "#46455b",
                  fontSize: "1.8rem",
                  fontWeight: "800",
                  textAlign: "center",
                }}
              >
                Conseil aux Entreprises et Startups
              </div>
              <div style={{ textAlign: "justify", marginTop: "20px" }}>
                Nos avis et recommandations aideront votre entreprise/startup à
                prendre les bonnes décisions sur l'usage de la Technologie des
                Registres Distribués (Blockchain) au profit de votre activité.
              </div>
            </div>
          </div>
          <div className={classes.ConseilImg} />
        </div>
        <div
          className={classes.Services}
          style={{ backgroundColor: "#F2F2F2" }}
        >
          <div className={classes.Left}>
            <div
              className={classes.FlexColumn}
              style={{
                padding: "10px",
                color: "#8c72cc",
                height: "300px",
                fontSize: "1.1rem",
              }}
            >
              <div
                style={{
                  color: "#46455b",
                  fontSize: "1.8rem",
                  fontWeight: "800",
                  textAlign: "center",
                }}
              >
                Formation
              </div>
              <div style={{ textAlign: "justify", marginTop: "20px" }}>
                Nous organisons des ateliers et des séminaires sur la Blockchain
                et ses applications. Nous offrons également des formations
                certifiées de Développeur Blockchain.
              </div>
            </div>
          </div>
          <div className={classes.FormationImg} />
        </div>
      </div>
      {/* Nos Projets */}
      <div
        className={classes.FlexColumn}
        style={{ backgroundColor: "#46455b" }}
      >
        <div className={classes.Title}>Nos Solutions</div>
        <div
          style={{
            backgroundColor: "#8c72cc",
            height: "5px",
            width: "75px",
            marginTop: "-35px",
          }}
        />
        <div className={classes.Services}>
          <div className={classes.SolutionItem}>
            <div className={classes.CardImg}>
              <img src={lamonaiImg} style={{ height: "55px" }} alt="" />
            </div>
            <div>
              Solution d'avoir digital et de fidélisation des clients
            </div>
            <a
              href="https://www.lamonai.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#8c72cc",
                textDecoration: "inherit",
                margin: "15px auto",
              }}
            >
              En savoir plus...
            </a>
          </div>
          <div className={classes.SolutionItem}>
            <div className={classes.CardImg}>
              <img src={rensfiImg} style={{ height: "55px" }} alt="" />
            </div>
            <div>
              Registre numérique (pas besoin de connexion Internet)
            </div>
            <a
              href="https://rensfi.cladjen.tech/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#8c72cc",
                textDecoration: "inherit",
                margin: "15px auto",
              }}
            >
              En savoir plus...
            </a>
          </div>
          <div className={classes.SolutionItem}>
            <div className={classes.CardImg}>
              <img src={efacUSSDImg} style={{ height: "38px" }} alt="" />
            </div>
            <div>
              Application de gestion de codes USSD (pas besoin de connexion Internet)
            </div>
            <a
              href="https://play.google.com/store/apps/details?id=com.cladjen.EfacUSSD"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#8c72cc",
                textDecoration: "inherit",
                margin: "15px auto",
              }}
            >
              Play Store
            </a>
            <a
              href="https://apps.apple.com/us/app/efacussd/id1660293658"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#8c72cc",
                textDecoration: "inherit",
              }}
            >
              App Store
            </a>
          </div>
          <div className={classes.SolutionItem}>
            <div className={classes.CardImg}>
              <img src={comraavImg} style={{ height: "48px" }} alt="" />
            </div>
            <div>
              Plateforme de commande et de réservation en ligne
            </div>
            <a
              href="https://comraav.cladjen.tech/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#8c72cc",
                textDecoration: "inherit",
                margin: "15px auto",
              }}
            >
              En savoir plus...
            </a>
          </div>
          <div className={classes.SolutionItem}>
            <div className={classes.CardImg}>
              <img src={lavevImg} style={{ height: "70px" }} alt="" />
            </div>
            <div>
              Plateforme de recherche de
              produits pharmaceutiques.
            </div>
            <a
              href="https://www.lavev.africa/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#8c72cc",
                textDecoration: "inherit",
                margin: "15px auto",
              }}
            >
              En savoir plus...
            </a>
          </div>
          <div className={classes.SolutionItem}>
            <div className={classes.CardImg}>
              <img src={tontinumImg} style={{ height: "55px" }} alt="" />
            </div>
            <div>
              Plateforme d'organisation de tontines en ligne et de vente à
              tempérament.
            </div>
            <a
              href="https://tontinum.cladjen.tech/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#8c72cc",
                textDecoration: "inherit",
                margin: "15px auto",
              }}
            >
              En savoir plus...
            </a>
          </div>
          <div className={classes.SolutionItem}>
            <div className={classes.CardImg}>
              <img src={atikaImg} style={{ height: "80px" }} alt="" />
            </div>
            <div>
              Projet d'optimisation des procédures d'assurance
            </div>
            <a
              href="https://atika.cladjen.tech/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#8c72cc",
                textDecoration: "inherit",
                margin: "15px auto",
              }}
            >
              En savoir plus...
            </a>
          </div>
        </div>
      </div>
      {/* Notre équipe 
      <div className={classes.FlexColumn}>
        <div className={classes.Title}>Notre équipe</div>
        <div style={{ backgroundColor: '#8c72cc', height: '5px', width: '75px', marginTop: '-35px' }} />
        <div className={classes.FlexRow} style={{ flexWrap: 'wrap', marginTop: '30px', backgroundColor: '#8c72cc', padding: '30px' }}>
          <div className={classes.PersonCard}>
            <img src={traore} className={classes.PersonImg} alt='' />
            <span className={classes.Nom}>Nazé Diakalia TRAORE</span>
            <span className={classes.Profil}>Ingénieur R&D Blockchain / CEO</span>
          </div>
          <div className={classes.PersonCard}>
            <img src={frederic} className={classes.PersonImg} alt='' />
            <span className={classes.Nom}>Frédéric Junior SOMDA</span>
            <span className={classes.Profil}>Conseiller en Assurance et Services Financiers / COO</span>
          </div>
          <div className={classes.PersonCard}>
            <img src={syndia} className={classes.PersonImg} alt='' />
            <span className={classes.Nom}>Syndia Sidora AFIABOU</span>
            <span className={classes.Profil}>Juriste / Chargée de Relations Publiques</span>
          </div>
          <div className={classes.PersonCard}>
            <img src={rachida} className={classes.PersonImg} alt='' />
            <span className={classes.Nom}>Rachidatou FOFANA</span>
            <span className={classes.Profil}>Communicatrice / Directrice Marketing et Commerciale</span>
          </div>
          <div className={classes.PersonCard}>
            <img src={lamoussa} className={classes.PersonImg} alt='' />
            <span className={classes.Nom}>Sié Lamoussa OUATTARA</span>
            <span className={classes.Profil}>Analyste Programmeur / Développeur Full-Stack</span>
          </div>
          <div className={classes.PersonCard}>
            <img src={simon} className={classes.PersonImg} alt='' />
            <span className={classes.Nom}>Simon Wilfried YOUGOUBARE</span>
            <span className={classes.Profil}>Analyste Programmeur / Développeur Full-Stack</span>
          </div>
          <div className={classes.PersonCard}>
            <img src={inoussa} className={classes.PersonImg} alt='' />
            <span className={classes.Nom}>Inoussa LEGRENE</span>
            <span className={classes.Profil}>Analyste Programmeur / Développeur Full-Stack</span>
          </div>
        </div>
      </div>
      /* Nous contacter */}
      <div
        className={classes.FlexColumn}
        style={{ backgroundColor: "#F2F2F2" }}
      >
        <div className={classes.Title}>Notre actualité</div>
        <div
          style={{
            backgroundColor: "#8c72cc",
            height: "5px",
            width: "75px",
            marginTop: "-35px",
            marginBottom: "30px",
          }}
        />
        <div className={classes.FlexColumn}>
          <iframe
            className={classes.Youtube}
            src="https://www.youtube.com/embed/zUAV_FyC2yk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div
        className={classes.FlexColumn}
        style={{ backgroundColor: "#F2F2F2" }}
      >
        <div className={classes.Title}>Nous contacter</div>
        <div
          style={{
            backgroundColor: "#8c72cc",
            height: "5px",
            width: "75px",
            marginTop: "-35px",
            marginBottom: "30px",
          }}
        />
        <div className={classes.FlexColumn}>
          <Formik
            initialValues={formInitialValues}
            validate={formValidation}
            onSubmit={formSubmission}
          >
            <Form className={classes.FormContainer}>
              <div className={classes.FormGroup}>
                <span>Nom et prénom (s): </span>
                <Field type="text" name="nom" style={{ width: "100%" }} />
                <ErrorMessage
                  name="nom"
                  component="div"
                  style={{ color: "#FF0077" }}
                />
              </div>
              <div className={classes.FormGroup}>
                <span>Votre email: </span>
                <Field type="email" name="email" style={{ width: "100%" }} />
                <ErrorMessage
                  name="email"
                  component="div"
                  style={{ color: "#FF0077" }}
                />
              </div>
              <div className={classes.FormGroup}>
                <span>Votre entreprise/startup/institut: </span>
                <Field type="text" name="structure" style={{ width: "100%" }} />
                <ErrorMessage
                  name="structure"
                  component="div"
                  style={{ color: "#FF0077" }}
                />
              </div>
              <div className={classes.FormGroup}>
                <span>Votre message: </span>
                <Field
                  as="textarea"
                  name="message"
                  rows={5}
                  style={{ width: "100%" }}
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  style={{ color: "#FF0077" }}
                />
              </div>
              <div>{showSpinner ? spinnerDiv : statusText}</div>
              <button
                type="submit"
                className={classes.SubmitBtn}
                disabled={showSpinner}
              >
                ENVOYER
              </button>
            </Form>
          </Formik>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LandingPage;
