import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Landing from './Components/LandingPage/LandingPage';

class App extends Component {

  render(){

    return <Landing />;
  }
}

export default App;
