import React from "react";

import classes from "./Footer.module.css";
import logoGrisImg from "../../asset/img/logo-gris.svg";
import phone from "../../asset/img/phone.svg";
import email from "../../asset/img/email.svg";
import location from "../../asset/img/location.svg";
import facebook from "../../asset/img/facebook.svg";
import linkedin from "../../asset/img/linkedin.svg";

const Footer = () => (
  <div className={classes.Footer}>
    <img src={logoGrisImg} style={{ height: "60px" }} alt="" />
    <div className={classes.Statut}>
      <span>Société A Responsabilité Limitée au capital de 1 000 000 FCFA</span>
      <div>
        RCCM: <span style={{ color: "#8c72cc" }}>BFOUA2020B0337</span> - IFU:{" "}
        <span style={{ color: "#8c72cc" }}>00131101E</span>
      </div>
    </div>
    <div
      className={classes.Flex}
      style={{ marginTop: "35px", marginLeft: "15px" }}
    >
      <img src={location} style={{ width: "15px" }} alt="" />
      <span style={{ fontSize: ".9rem", marginLeft: "12px" }}>
        Wem-Tenga, Ouagadougou, Burkina Faso
      </span>
    </div>
    <div
      className={classes.Flex}
      style={{ marginTop: "15px", marginLeft: "15px" }}
    >
      <img src={phone} style={{ width: "20px" }} alt="" />
      <span style={{ fontSize: ".9rem", marginLeft: "12px" }}>
        (+226) 25 39 37 81
      </span>
    </div>
    <div
      className={classes.Flex}
      style={{ marginTop: "15px", marginLeft: "15px" }}
    >
      <img src={email} style={{ width: "20px" }} alt="" />
      <span style={{ fontSize: ".9rem", marginLeft: "12px" }}>
        info@cladjen.tech
      </span>
    </div>
    <div
      className={classes.Flex}
      style={{ marginTop: "15px", marginLeft: "15px" }}
    >
      <a
        href="https://www.facebook.com/cladjen"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#8c72cc",
          textDecoration: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={facebook} style={{ width: "20px" }} alt="" />
        <span style={{ fontSize: ".9rem", marginLeft: "12px" }}>Facebook</span>
      </a>
    </div>
    <div
      className={classes.Flex}
      style={{ marginTop: "15px", marginLeft: "15px" }}
    >
      <a
        href="https://www.linkedin.com/company/cladjen"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#8c72cc",
          textDecoration: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={linkedin} style={{ width: "20px" }} alt="" />
        <span style={{ fontSize: ".9rem", marginLeft: "12px" }}>LinkedIn</span>
      </a>
    </div>
    <div style={{ marginTop: "35px", color: "#8c72cc", textAlign: "center" }}>
      Copyright © 2020-{new Date().getFullYear()} CLADJEN. Tous droits réservés
    </div>
  </div>
);

export default Footer;
